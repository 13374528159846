import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import defaultavatar from "../../../images/user_avatar_50.png";
import { facebook_profile_url } from "../../../backend/config_backend";

const TopUsersChart = ({ facebookPosts, onUsernameClick }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (facebookPosts && facebookPosts.user_stats) {
      const chart = echarts.init(chartRef.current);

      const userArray = Object.entries(facebookPosts.user_stats);
      userArray.sort(
        ([, a], [, b]) => (b ? b.numberOfPosts : 0) - (a ? a.numberOfPosts : 0)
      );

      const topUsers = userArray.slice(0, 10);

      const options = {
        dataset: {
          source: topUsers.map(([username, userData]) => ({
            username,
            numberOfPosts: userData ? userData.numberOfPosts : 0,
          })),
        },
        xAxis: {
          type: "category",
          inverse: true,
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 45,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "bar",
            encode: {
              x: "username",
              y: "numberOfPosts",
            },
            barWidth: 50,
            barCategoryGap: "10%",
            sort: "none",
            itemStyle: {
              color: "#1877F2",
              borderRadius: [10, 10, 0, 0],
            },
          },
        ],
      };

      // Dispose the previous chart instance
      chart.dispose();

      // Create new chart instance
      const newChart = echarts.init(chartRef.current);
      newChart.setOption(options);

      topUsers.forEach(([username, userData], index) => {
        let avatarUrl = facebook_profile_url + username + ".png";
        const img = new Image();

        img.onerror = () => {
          // اگر تصویر بارگذاری نشد، از تصویر پیش‌فرض استفاده می‌کنیم
          const barCoord = newChart.convertToPixel("grid", [
            username,
            userData ? userData.numberOfPosts : 0,
          ]);

          newChart.setOption({
            graphic: [
              {
                type: "image",
                id: username,
                right: "90%",
                left: barCoord[0] - 25,
                top: barCoord[1] - 55,
                z: 100,
                bounding: "raw",
                style: {
                  image: defaultavatar, // استفاده از تصویر پیش‌فرض
                  width: 50,
                  height: 50,
                },
                clipPath: createCircleClipPath(25),
                onclick: () => onUsernameClick(username),
              },
            ],
          });
        };

        // Set the source to the avatar URL
        img.src = avatarUrl;
        
        img.onload = () => {
          if (newChart) {
            const barCoord = newChart.convertToPixel("grid", [
              username,
              userData ? userData.numberOfPosts : 0,
            ]);

            newChart.setOption({
              graphic: [
                {
                  type: "image",
                  id: username,
                  right: "90%",
                  left: barCoord[0] - 25,
                  top: barCoord[1] - 55,
                  z: 100,
                  bounding: "raw",
                  style: {
                    image: img.src, // استفاده از تصویر از URL
                    width: 50,
                    height: 50,
                  },
                  clipPath: createCircleClipPath(25),
                  onclick: () => onUsernameClick(username),
                },
              ],
            });
          }
        };
      });

      function createCircleClipPath(radius) {
        return {
          type: "circle",
          shape: {
            cx: radius,
            cy: radius,
            r: radius,
          },
        };
      }
    }
  }, [facebookPosts, onUsernameClick]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "500px",
        borderBottom: "2px solid #ccc",
        paddingBottom: "30px",
        marginBottom: "30px",
      }}
    />
  );
};

export default TopUsersChart;
